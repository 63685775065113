import { LanguageCode, LanguageCodes } from '@app-shared/enums/language-codes.enum';
import { MapWithDefault } from '@app-shared/helpers/helpers';

export enum BackendExpectedLanguage {
  ENGLISH_USA = 'en',
  GERMAN_GERMANY = 'de',
  CZECH = 'cs',
  ENGLISH_CANADA = 'en-CA',
  ENGLISH_GREAT_BRITAIN = 'en-UK',
  SPANISH_SPAIN = 'es-ES',
  SPANISH_MEXICO = 'es-MX',
  FINNISH = 'fi',
  FRENCH_CANADA = 'fr-CA',
  FRENCH_FRANCE = 'fr-FR',
  ITALIAN_ITALY = 'it',
  JAPANESE = 'ja',
  DUTCH_NETHERLANDS = 'nl',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  PORTUGUESE_PORTUGAL = 'pt',
  RUSSIAN = 'ru',
  UKRAINIAN = 'uk',
}

export type BackendExpectedLanguageType = keyof typeof BackendExpectedLanguage;

/**
 * A map that returns a BackendExpectedLanguage given the language code.
 *
 * Example: 'en-GB' returns 'English-UK'
 */
export const languageCodeToBackendExpectedLanguageMap = new MapWithDefault<LanguageCodes, BackendExpectedLanguage>(
  BackendExpectedLanguage.ENGLISH_USA,
  [
    [LanguageCode.ENGLISH_USA, BackendExpectedLanguage.ENGLISH_USA],
    [LanguageCode.GERMAN_GERMANY, BackendExpectedLanguage.GERMAN_GERMANY],
    [LanguageCode.CZECH, BackendExpectedLanguage.CZECH],
    [LanguageCode.ENGLISH_CANADA, BackendExpectedLanguage.ENGLISH_CANADA],
    [LanguageCode.ENGLISH_GREAT_BRITAIN, BackendExpectedLanguage.ENGLISH_GREAT_BRITAIN],
    [LanguageCode.SPANISH_SPAIN, BackendExpectedLanguage.SPANISH_SPAIN],
    [LanguageCode.SPANISH_MEXICO, BackendExpectedLanguage.SPANISH_MEXICO],
    [LanguageCode.FINNISH, BackendExpectedLanguage.FINNISH],
    [LanguageCode.FRENCH_CANADA, BackendExpectedLanguage.FRENCH_CANADA],
    [LanguageCode.FRENCH_FRANCE, BackendExpectedLanguage.FRENCH_FRANCE],
    [LanguageCode.ITALIAN_ITALY, BackendExpectedLanguage.ITALIAN_ITALY],
    [LanguageCode.JAPANESE, BackendExpectedLanguage.JAPANESE],
    [LanguageCode.DUTCH_NETHERLANDS, BackendExpectedLanguage.DUTCH_NETHERLANDS],
    [LanguageCode.NORWEGIAN, BackendExpectedLanguage.NORWEGIAN],
    [LanguageCode.POLISH, BackendExpectedLanguage.POLISH],
    [LanguageCode.PORTUGUESE_PORTUGAL, BackendExpectedLanguage.PORTUGUESE_PORTUGAL],
    [LanguageCode.RUSSIAN, BackendExpectedLanguage.RUSSIAN],
    [LanguageCode.UKRAINIAN, BackendExpectedLanguage.UKRAINIAN],
  ],
);

/**
 * A map that returns a LanguageCode given the BackendExpectedLanguage.
 *
 * Example: 'English-UK' returns 'en-GB'
 */
export const backendExpectedLanguageToLanguageCodeMap = new MapWithDefault<BackendExpectedLanguage, LanguageCodes>(
  LanguageCode.ENGLISH_USA,
  [
    [BackendExpectedLanguage.ENGLISH_USA, LanguageCode.ENGLISH_USA],
    [BackendExpectedLanguage.GERMAN_GERMANY, LanguageCode.GERMAN_GERMANY],
    [BackendExpectedLanguage.CZECH, LanguageCode.CZECH],
    [BackendExpectedLanguage.ENGLISH_CANADA, LanguageCode.ENGLISH_CANADA],
    [BackendExpectedLanguage.ENGLISH_GREAT_BRITAIN, LanguageCode.ENGLISH_GREAT_BRITAIN],
    [BackendExpectedLanguage.SPANISH_SPAIN, LanguageCode.SPANISH_SPAIN],
    [BackendExpectedLanguage.SPANISH_MEXICO, LanguageCode.SPANISH_MEXICO],
    [BackendExpectedLanguage.FINNISH, LanguageCode.FINNISH],
    [BackendExpectedLanguage.FRENCH_CANADA, LanguageCode.FRENCH_CANADA],
    [BackendExpectedLanguage.FRENCH_FRANCE, LanguageCode.FRENCH_FRANCE],
    [BackendExpectedLanguage.ITALIAN_ITALY, LanguageCode.ITALIAN_ITALY],
    [BackendExpectedLanguage.JAPANESE, LanguageCode.JAPANESE],
    [BackendExpectedLanguage.DUTCH_NETHERLANDS, LanguageCode.DUTCH_NETHERLANDS],
    [BackendExpectedLanguage.NORWEGIAN, LanguageCode.NORWEGIAN],
    [BackendExpectedLanguage.POLISH, LanguageCode.POLISH],
    [BackendExpectedLanguage.PORTUGUESE_PORTUGAL, LanguageCode.PORTUGUESE_PORTUGAL],
    [BackendExpectedLanguage.RUSSIAN, LanguageCode.RUSSIAN],
    [BackendExpectedLanguage.UKRAINIAN, LanguageCode.UKRAINIAN],
  ],
);

interface AppLanguage {
  flag: string;
  name: string;
  language_code: LanguageCodes;
}

/**
 * A list of available app languages with their localized names(TODO),
 * their flag image name and their language code.
 */
export const availableLanguages: AppLanguage[] = [
  { name: 'English (US)', flag: 'us', language_code: LanguageCode.ENGLISH_USA },
  { name: 'Deutsch', flag: 'de', language_code: LanguageCode.GERMAN_GERMANY },
  { name: 'Čeština', flag: 'cz', language_code: LanguageCode.CZECH },
  { name: 'English (CA)', flag: 'ca', language_code: LanguageCode.ENGLISH_CANADA },
  { name: 'Français (CA)', flag: 'ca', language_code: LanguageCode.FRENCH_CANADA },
  { name: 'English (UK)', flag: 'gb', language_code: LanguageCode.ENGLISH_GREAT_BRITAIN },
  { name: 'Español', flag: 'es', language_code: LanguageCode.SPANISH_SPAIN },
  { name: 'Español (MX)', flag: 'mx', language_code: LanguageCode.SPANISH_MEXICO },
  { name: 'Suomalainen', flag: 'fi', language_code: LanguageCode.FINNISH },
  { name: 'Français', flag: 'fr', language_code: LanguageCode.FRENCH_FRANCE },
  { name: 'Italiano', flag: 'it', language_code: LanguageCode.ITALIAN_ITALY },
  { name: '日本語', flag: 'jp', language_code: LanguageCode.JAPANESE },
  { name: 'Nederlands', flag: 'nl', language_code: LanguageCode.DUTCH_NETHERLANDS },
  { name: 'Norsk', flag: 'no', language_code: LanguageCode.NORWEGIAN },
  { name: 'Polski', flag: 'pl', language_code: LanguageCode.POLISH },
  { name: 'Português', flag: 'pt', language_code: LanguageCode.PORTUGUESE_PORTUGAL },
  { name: 'Русский', flag: 'ru', language_code: LanguageCode.RUSSIAN },
  { name: 'Українська', flag: 'ua', language_code: LanguageCode.UKRAINIAN },
].sort((lang1, lang2) => lang1.name.toLowerCase().localeCompare(lang2.name.toLowerCase()));
